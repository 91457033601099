import React, { useState } from "react"
import { connect as connectFela } from "react-fela"
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import * as R from "ramda"
import { graphql } from "gatsby"

import colors from "theme/colors"
import Container from "components/container"
import P from "components/typography/P"
import H2 from "components/typography/H2"
import H3 from "components/typography/H3"
import H5 from "components/typography/H5"
import fluidValues from "utilities/fluidValues"
import PlaybookDownloadButton from "components/PlaybookDownloadButton"
import slugify from "utilities/slugify"
import calc from "utilities/calc"

/* Component */
export default connectFela(({ flip }) => ({
  container: {
    flexWrap: 'wrap',
    color: colors.colorConsole500,
    ipadMiniAndUp: {
      flexWrap: 'nowrap'
    }
  },
  downloadButton: {
    padding: 2,
    display: 'block',
    marginTop: 'auto',
    ipadMiniAndUp: {
      // marginTop: '2rem',
      extend: {
        condition: flip,
        style: {
          ...fluidValues({
            paddingLeft: [69, 110]
          }),
        }
      },
    },
    phoneOnly: {
      marginLeft: 'auto',
      marginTop: '-4rem',
      marginRight: 0
    }
  },
  image: {
    marginBottom: 32,
    flex: '1 1 50%',
    ipadMiniAndUp: {
      marginBottom: 0,
      ...fluidValues({
        maxWidth: [259, 547]
      }),
      extend: {
        condition: flip,
        style: {
          order: 1,
        }
      },
    }
  },
  content:{
    flex: '1 1 50%',
    display: 'flex',
    flexFlow: 'column',
    marginLeft: calc(48, 107, 768, 1440),
    ipadMiniAndUp: {
      extend: {
        condition: flip,
        style: {
          marginLeft: 0,
          marginRight: calc(17, 60, 768, 1440),
        }
      }
    }
  },
  intro: {
    fontWeight: 700,
    ...fluidValues({
      paddingRight: [12, 24]
    }),
    phoneOnly: {
      width: '80%'
    },
    '@media(min-width: 769px)': {
      display: 'none'
    }
  },
  introMedium: {
    fontWeight: 700,
    ...fluidValues({
      paddingRight: [12, 24]
    }),
    ipadMiniAndUp: {
      maxWidth: 469,
      marginBottom: 0,
    },
    '@media(max-width: 769px)': {
      display: 'none'
    },
    ipadMiniAndUp: {
      extend: {
        condition: flip,
        style: {
          paddingRight: '0px !important',
          ...fluidValues({
            paddingLeft: [69, 110]
          }),
        }
      }
    },
  },
  containerWithBar: {
    position: 'relative',

    ipadMiniAndUp: {
      extend: {
        condition: flip,
        style: {
          ...fluidValues({
            paddingLeft: [69, 110]
          }),
        }
      },
    },
    onAfter: {
      content: '""',
      backgroundColor: colors.colorConsole500,
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: '22%',
      ...fluidValues({
        width: [12, 24]
      }),
      ipadMiniAndUp: {
        extend: {
          condition: flip,
          style: {
            right: 'unset',
            left: 0,
          }
        }
      }
    }
  },
  title: {
    whiteSpace: 'pre-wrap'
  }
}))(({ styles, rules, title, client, intro, image, case_study, index }) => {
  return <Container flex noPadding extend={{styles: rules.container}} id={slugify(title)}>
           <div className={styles.image}>
             <Img image={image.asset.gatsbyImageData} alt={title} />
           </div>
           <div className={styles.content}>
             <div className={styles.containerWithBar}>
               <H2 neo extend={{styles: rules.title}}>{title}</H2>
               <P version={"quote"}>Client | {client}</P>
             </div>
             <P version={"body"} extend={{styles: rules.intro}}>{intro}</P>
             <P version={"medium"} extend={{styles: rules.introMedium}}>{intro}</P>
             <PlaybookDownloadButton
               nodownload
               file={case_study.file}
               parentRules={rules.downloadButton} />
           </div>
         </Container>
})

export const CaseStudyPageSectionFrag = graphql`
fragment caseStudyPageSectionFrag on SanityCaseStudyPageSection {
       title
       client
       intro
       image {
         credits
         asset {
           gatsbyImageData(layout: CONSTRAINED, width:  1440)
         }
       }
       case_study {
         title
         file {
          asset {
            localFile {
              publicURL
              name
              internal {
                contentDigest
              }
            }
            originalFilename
          }
         }
       }
}`
