import React from "react"
import { connect as connectFela } from "react-fela"
import { extractFilename } from '../commands/playbookUrl'

import ViewButton from "components/ViewButton"
import DownloadButton from "components/DownloadButton"

const PlaybookDownloadButton = ({rules, parentRules, styles, file, onClick, nodownload}) => {
  const filename = extractFilename(file)

  return (<>
            {file?.asset && nodownload &&
             <ViewButton
               onClick={(e) => onClick(e)}
               download={nodownload ? null : filename}
               extend={{styles: parentRules}}
               to={file} />}

            {file?.asset && !nodownload &&
             <DownloadButton
               onClick={(e) => onClick(e)}
               download={nodownload ? null : filename}
               extend={{styles: parentRules}}
               to={file} />}
  </>)
}

const styles = (props) => ({})

export default connectFela(styles)(PlaybookDownloadButton)
