import React, { useState } from "react"
import { connect as connectFela } from "react-fela"
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import * as R from "ramda"
import { graphql } from "gatsby"

import colors from "theme/colors"
import Container from "components/container"
import P from "components/typography/P"
import H2 from "components/typography/H2"
import H3 from "components/typography/H3"
import H5 from "components/typography/H5"
import fluidValues from "utilities/fluidValues"
import PlaybookDownloadButton from "components/PlaybookDownloadButton"
import slugify from "utilities/slugify"
import calc from "utilities/calc"

/* Component */
export default connectFela(({ flip }) => ({
  container: {
    flexFlow: 'column',
    color: colors.colorConsole500,
    marginTop: calc(0, -118, 320, 768),
    '@media(max-width: 600px)': {
      marginTop: 0
    },
    '@media(min-width: 1441px)': {
      marginTop: '-80px'
    },
    ipadMiniAndUp: {
      marginTop: calc(-118, -80, 768, 1440)
    },
  },
  downloadButton: {
    padding: 2,
    display: 'block',
    marginTop: 'auto',
    marginBottom: '2rem',
    marginLeft: 'auto',
    flex: '1 0 auto',
    ipadMiniAndDown: {
      marginLeft: 0,
      marginRight: 'auto',
    },
    phoneOnly: {
      marginTop: '-4rem',
      marginLeft: 0
    }
  },
  image: {
    ...fluidValues({
      marginBottom: [32, 60],
    })
  },
  content:{
    display: 'flex',
    flexFlow: 'column',
    ipadMiniAndUp: {
      flexFlow: 'row'
    }
  },
  intro: {
    fontWeight: 700,
    ...fluidValues({
      paddingRight: [12, 24]
    }),
    ipadMiniAndUp: {
      maxWidth: 469,
      marginBottom: 0,
    },
    '@media(min-width: 769px)': {
      display: 'none'
    },
    phoneOnly: {
      width: '80%',
      marginLeft: 'auto'
    }
  },
  introMedium: {
    fontWeight: 700,
    ...fluidValues({
      paddingRight: [12, 24]
    }),
    ipadMiniAndUp: {
      maxWidth: 469,
      marginBottom: 0,
    },
    '@media(max-width: 769px)': {
      display: 'none'
    }
  },
  containerWithBar: {
    flex: '0 1 auto',
    position: 'relative',
    paddingLeft: calc(69, 58, 320, 768),
    ipadMiniAndUp: {
      paddingLeft: calc(58, 110, 768, 1440),
    },
    marginRight: calc(44, 110, 768, 1440),

    onAfter: {
      content: '""',
      backgroundColor: colors.colorConsole500,
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: '22%',
      ...fluidValues({
        width: [12, 24]
      }),
    }
  },
  title: {
    whiteSpace: 'pre-wrap'
  }
}))(({ styles, rules, title, client, intro, image, case_study, index }) => {
  return <Container flex noPadding extend={{styles: rules.container}} id={slugify(title)}>
           <div className={styles.image}>
             <Img image={image.asset.gatsbyImageData} alt={title} />
           </div>
           <div className={styles.content}>
             <div className={styles.containerWithBar}>
               <H2 neo extend={{styles: rules.title}}>{title}</H2>
               <P version={"quote"}>Client | {client}</P>
             </div>
             <P version={"body"} extend={{styles: rules.intro}}>{intro}</P>
             <P version={"medium"} extend={{styles: rules.introMedium}}>{intro}</P>
             <PlaybookDownloadButton
               nodownload
               file={case_study.file}
               parentRules={rules.downloadButton} />
           </div>
         </Container>
})
