import React from "react"
import { graphql } from "gatsby"
import { connect as connectFela } from "react-fela"

import ColorContext from "context/ColorContext"
import Container from "components/container"
import H2 from "components/typography/H2"
import H4 from "components/typography/H4"
import Header from '../consult/Header'
import Layout from "components/layout"
import P from "components/typography/P"
import { getHexColor } from "theme/colors"
import fluidValues from "utilities/fluidValues"
import slugify from "utilities/slugify"
import CaseStudyPageSection from "./case-studies-page.section"
import Featured from "./featured"

export default connectFela(({data: { sanityCaseStudiesPage: { headerColor, color }}}) => ({
  sections: {
    maxWidth: 1286,
    ...fluidValues({
      marginBottom: [60, 120]
    })
  },
  featuredContainer: {
    background: 'linear-gradient(' + getHexColor(headerColor, '500') + ' 0%,' + getHexColor(headerColor, '500') + ' 28%, white 28%, white 100%)',
  },
  featured: {
    maxWidth: 1286,
    ...fluidValues({
      marginBottom: [60, 125]
    })
  }
}))(({
  pageContext,
  path,
  data: {
    jobs,
    settings,
    sanityCaseStudiesPage : {
      title,
      meta,
      slug,
      color,
      header,
      headerColor,
      casestudies
    }
  }, rules, styles
}) => {
  const featured = casestudies.shift();
  return (
    <ColorContext.Provider value={color}>
      <Layout
        pageColor={getHexColor(color, '500')}
        linkColor={getHexColor(color, '200')}
        title={title}
        slug={{ current: path }}
        meta={meta || {}}
        settings={settings}
        jobs={jobs}
        country={pageContext.country}
      >
        {header && <Header
                     {...header}
                     header
                     extend={{styles: rules.header}}
                     copy={header._rawCopy}
                     hidesidebar={false}
                     bgColor={getHexColor(headerColor, '500')}
                     color={(header.fontcolor !== 'Light' ? getHexColor('Console', '500') : getHexColor('Canvas', '50'))}
                   />}

        {featured && <div className={styles.featuredContainer}><Container tag={'section'} extend={{styles: rules.featured}}>
                       <Featured {...featured} />
                     </Container></div>}

        {casestudies.map((case_study_section, index) => {
          return <Container tag={'section'} extend={{styles: rules.sections}} key={index}>
                   <CaseStudyPageSection {...case_study_section} flip={index % 2} />
          </Container>
        })}

      </Layout>
    </ColorContext.Provider>
  )
})

export const pageQuery = graphql`
query($country: String!, $slug: String!) {
    settings: sanitySettings(countrySet: { eq: $country }) {
        ...Settings
    }

    jobs: allSanityJob(filter: { countrySet: { eq: $country } }) {
        edges {
            node {
                ...Jobs
            }
        }
    }

    sanityCaseStudiesPage(slug: { current: { eq: $slug }}) {
      countrySet
      title
      meta {
        title
        description
        other {
          property
          content
        }
      }
      slug {
        current
      }
      color
      headerColor
      header {
        title
        _rawCopy
        link {
          ...sanityLinkFrag
        }
        category
        image {
          credits
          asset {
            gatsbyImageData(layout: CONSTRAINED, width:  1440)
          }
        }
        fontcolor
      }

      casestudies {
          ...caseStudyPageSectionFrag
      }
    }
  }
`
